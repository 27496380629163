.forPatients {
  .forPatients__main {
    background: url("../../../assets/img_easy/patient_bg.jpg") no-repeat center/cover;
    padding: 100px 0;
    min-height: 780px;

    .forPatients__text {
      max-width: 500px;
      margin-right: 20px;
      width: 100%;
      padding-top: 100px;
      .btnWrap {
        justify-content: flex-start;
        .btn {
          background: #e57373;
        }
      }
      h1 {
        font-family: "Roboto Slab", serif;
        font-weight: 700;
        font-size: 60px;
        color: #e57373;
      }
      p {
        margin: 30px 0 50px;
        font-size: 24px;
      }
    }
    .forPatients__pic {
      img {
        max-width: 100%;
      }
    }
  }
  .pPluses {
    background: linear-gradient(#1d363bc0 0%, #1d363bc0 100%),
      url("../../../assets/img_easy/patients2_bg.jpg") no-repeat center/cover;
    padding: 50px 0;
    .pPluses__item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      .pPluses__pic {
        margin-right: 80px;
        min-width: 70px;
        display: flex;
        justify-content: center;
        img {
          max-width: 100%;
        }
      }
      .pPluses__text {
        max-width: 540px;
        p {
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }
  .pWhy {
    background: url("../../../assets/img_easy/patients_why.png") no-repeat center/cover;
    padding: 150px 0;
    h2 {
      font-size: 36px;
      color: #00838f;
      margin-bottom: 50px;
    }
    p {
      // font-size: 18px;
      line-height: normal;
    }
  }
  .whatAreAligners {
    background: rgb(28, 195, 201);
    background: linear-gradient(
      90deg,
      rgba(28, 195, 201, 0.83) 0%,
      rgba(28, 195, 201, 0.12) 16%,
      rgba(255, 255, 255, 0.66) 21%,
      rgba(255, 255, 255, 0.6) 39%,
      rgba(255, 115, 115, 1) 100%
    );
    position: relative;
    padding: 150px 0;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1365px) {
      padding-bottom: 0;
    }
    .containerSmall {
      order: 1;
    }
    .whatAreAligners__pic {
      position: absolute;
      bottom: -6px;
      right: 0;
      max-width: 600px;
      @media screen and (max-width: 1800px) {
        max-width: 400px;
      }
      @media screen and (max-width: 1500px) {
        max-width: 330px;
      }
      @media screen and (max-width: 1365px) {
        position: relative;
        bottom: auto;
        right: 0;
        margin-top: auto;
        margin-left: auto;
        order: 2;
        display: flex;
      }
      img {
        max-width: 100%;
      }
    }
    h2 {
      font-size: 36px;
      color: #ef5350;
      margin-bottom: 50px;
    }
    p {
      // font-size: 18px;
      line-height: normal;
      max-width: 670px;
    }
  }
  .pWhat {
    padding: 100px 0;

    .container {
      position: relative;
      z-index: 10;
    }
    h2 {
      font-size: 36px;
      color: #444;
      text-align: center;
      margin-bottom: 25px;
      position: relative;
      z-index: 30;
    }
    p {
      line-height: normal;
    }
    .pWhat__wrap {
      display: flex;
      align-items: center;
      padding: 50px 100px;
      // box-shadow: 15px 5px 10px rgba(0, 0, 0, 0.2);
      position: relative;
      @media screen and (max-width: 850px) {
        flex-direction: column;
      }
      @media screen and (max-width: 600px) {
        padding: 30px;
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 0 220px 220px 0;
        background: #fff;
        @media screen and (max-width: 600px) {
          border-radius: 0;
        }
      }
      &::after {
        content: "";
        position: absolute;
        top: -100px;
        left: 50px;
        z-index: 1;
        width: 100%;
        height: 130%;
        border-radius: 0 280px 280px 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fefefec2 16%, #76dbded5 75%, #1cc3c9 100%);
        @media screen and (max-width: 1365px) {
          left: 0;
          border-radius: 0;
        }
      }
      .pWhat__pic {
        margin-right: 30px;
        width: 100%;
        position: relative;
        z-index: 20;
        @media screen and (max-width: 850px) {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
        }
        img {
          max-width: 100%;
        }
      }
      .pWhat__text {
        width: 100%;
        position: relative;
        z-index: 20;
        .btnWrap {
          justify-content: flex-start;
          margin-top: 20px;
          .btn {
            background: #e57373;
          }
        }
      }
    }
  }
  .searchDoc {
    max-width: 1100px;
    margin: 0 auto 50px;

    position: relative;
    @media screen and (max-width: 710px) {
      margin-top: 80px;
    }
    .searchDoc__content {
      border-radius: 240px 0 0 240px;
      padding-left: 100px;
      box-shadow: -10px 0 5px -5px rgba(0, 0, 0, 0.2);
      background: #fff;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 10;
      @media screen and (max-width: 830px) {
        padding-bottom: 50px;
      }
      @media screen and (max-width: 710px) {
        flex-direction: column;
        border-radius: 0;
        padding: 50px 15px;
      }
      .searchDoc__company {
        position: absolute;
        top: -35px;
        right: 300px;
        @media screen and (max-width: 710px) {
          right: auto;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .searchDoc__text {
        padding-top: 100px;
        @media screen and (max-width: 710px) {
          padding-top: 0;
        }
        h2 {
          font-size: 36px;
          color: #006064;
        }
        p {
          max-width: 580px;
          margin: 30px 0 50px;
        }
      }
      .searchDoc__pic {
        margin-top: -70px;
        @media screen and (max-width: 710px) {
          margin-top: 30px;
          img {
            max-width: 100%;
          }
        }
      }
    }
    .searchDoc__overlay {
      position: absolute;
      top: -35px;
      left: -20px;
      border-radius: 250px 0 0 250px;
      width: 100%;
      height: 110%;
      z-index: 1;
      background: linear-gradient(to left, rgba(255, 255, 255, 0), #fefefece 10%, #ffc2c2 100%);
      @media screen and (max-width: 710px) {
        border-radius: 0;
      }
    }
  }
  .containerSmall {
    max-width: 1000px;
    padding: 0 15px;
    margin: 0 auto;
  }
}
