.forDoctors {
  .start {
    background: url("../../../assets/img_easy/start_bg.jpg") no-repeat center top/cover;
    min-height: 800px;
    display: flex;
    @media screen and (max-width: 1500px) {
      min-height: 600px;
    }
    .container {
      flex-grow: 1;
    }
    .start__wrap {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 800px;
      .btnWrap {
        justify-content: flex-start;
      }
      h1 {
        font-family: "Roboto Slab", serif;
        font-weight: 700;
        font-size: 60px;
        color: #0097a7;
      }
      p {
        margin: 30px 0 50px;
        font-size: 24px;
      }
    }
  }
  .what {
    min-height: 450px;
    display: flex;
    justify-content: flex-end;
    .what__wrap {
      display: flex;
      align-items: center;
      max-width: 1600px;
      width: 90%;
      padding: 10px 15px 10px 100px;
      border-radius: 300px 0 0 300px;
      background: linear-gradient(to right, #8be2e1, rgba(255, 255, 255, 0.719) 50%, #fefefecb 75%, #fafafaad 100%),
        url("../../../assets/img_easy/what.jpg") no-repeat right center/contain;
      @media screen and (max-width: 992px) {
        width: 100%;
        flex-direction: column;
        border-radius: 0;
        padding: 30px 15px;
        .what__pic {
          order: 2;
          img {
            max-width: 100%;
          }
        }
      }
      h2 {
        font-size: 30px;
        font-family: "Roboto", sans-serif;
        font-weight: 400;
        color: #e57373;
        max-width: 800px;
        margin-bottom: 50px;
        @media screen and (max-width: 992px) {
          text-align: center;
          margin-bottom: 30px;
        }
      }
      p {
        max-width: 800px;
        margin: 0 auto;
        line-height: normal;
        @media screen and (max-width: 992px) {
          text-align: center;
        }
      }
    }
  }
  .redBox {
    position: relative;
    width: 90%;
    padding: 50px;
    @media screen and (max-width: 992px) {
      width: 100%;
      padding: 20px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), #fefefece 50%, #fff 100%);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      z-index: 10;
      border-radius: 0 220px 220px 0;
      @media screen and (max-width: 992px) {
        border-radius: 0;
      }
    }
    &::after {
      border-radius: 0 220px 220px 0;
      content: "";
      position: absolute;
      top: 10px;
      left: 20px;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), #fefefece 50%, #ffc2c2 100%);
      z-index: 1;
      @media screen and (max-width: 992px) {
        border-radius: 0;
        left: 0;
      }
    }
  }
  .why {
    padding: 100px 0;
    .why__wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      z-index: 20;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
    .why__text {
      h2 {
        font-size: 30px;
        font-weight: 400;
        color: #00838f;
        margin-bottom: 30px;
      }
      p {
        // font-size: 18px;
        line-height: normal;
        max-width: 700px;
      }
    }
    .why__pics {
      display: flex;
      flex-direction: column;
      img {
        max-width: 100%;
      }
    }
  }
  .web {
    background: linear-gradient(to right, #6647479a 0%, #6647479a 100%),
      url("../../../assets/img_easy/web_bg.jpg") no-repeat center top/cover;
    position: relative;
    padding: 50px 0;
    @media screen and (max-width: 810px) {
      display: flex;
      flex-direction: column;
    }
    .web__pic {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      img {
        max-width: 100%;
      }
      @media screen and (max-width: 1100px) {
        max-width: 500px;
      }
      @media screen and (max-width: 810px) {
        position: relative;
        top: 0;
        left: 0;
        transform: translateY(0);
        order: 2;
        margin-left: auto;
      }
    }
    h2 {
      font-size: 36px;
      color: #fff;
      font-family: "Roboto Slab", sans-serif;
      margin-bottom: 80px;
      @media screen and (max-width: 810px) {
        text-align: center;
      }
    }
    .web__items {
      .web__item {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
        @media screen and (max-width: 810px) {
          justify-content: center;
        }
        img {
          display: block;
          margin-right: 20px;
        }
        .web__text {
          color: #fff;
        }
      }
    }
  }
  .big {
    background: url("../../../assets/img_easy/big.png") no-repeat center top/cover;
    padding: 50px 0;
    .redBox {
      padding: 0 50px;
      &::after {
        top: -25px;
        @media screen and (max-width: 992px) {
          left: 0;
        }
      }
    }
    .blueBox {
      margin-left: auto;
      margin-bottom: 100px;
      &::before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), #fefefece 50%, #fff 100%);
        box-shadow: none;
        border-radius: 220px 0 0 220px;
        @media screen and (max-width: 992px) {
          border-radius: 0;
        }
      }
      &::after {
        top: 10px;
        left: -20px;
        width: 100%;
        height: 100%;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), #fefefece 50%, #8be2e1 100%);
        box-shadow: none;
        border-radius: 220px 0 0 220px;
        @media screen and (max-width: 992px) {
          border-radius: 0;
        }
      }
    }

    .big__wrap {
      position: relative;
      z-index: 20;
      padding: 30px;
      h2 {
        font-size: 36px;
        color: #e57373;
        margin-bottom: 30px;
      }
      p {
        line-height: normal;
        max-width: 900px;
      }
      &_red {
        position: relative;
        padding-left: 350px;
        @media screen and (max-width: 768px) {
          padding-left: 0;
        }
        .big__pic {
          position: absolute;
          bottom: -17px;
          left: 0;
          @media screen and (max-width: 768px) {
            position: relative;
            bottom: 0;
            display: block;
            margin: 0 auto;
          }
        }
        h2 {
          color: #006064;
        }
        .btnWrap {
          margin-top: 50px;
          justify-content: flex-end;
          @media screen and (max-width: 768px) {
            justify-content: center;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
.training {
  background: linear-gradient(#466b6b8c 0%, #466b6b8c 100%),
    url("../../../assets/img_easy/training.jpg") no-repeat center/cover;
  padding: 50px 0;
  h2 {
    text-align: center;
    font-size: 36px;
    color: #fff;
  }
  .training__icon {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
  p {
    font-size: 18px;
    color: #fff;
    max-width: 840px;
    margin: 0 auto 30px;
  }
}
