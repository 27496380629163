.blog {
  .blog__header {
    padding: 80px 15px;
    background: linear-gradient(to left, #ffffff00, #ffffffc9 40%, #fff 100%),
      url("../../../assets/img_easy/blog_bg.png") no-repeat center/cover;
    @media screen and (max-width: 576px) {
      padding: 20px 15px;
      margin-top: 50px;
    }
    h1 {
      font-family: "Roboto Slab", serif;
      max-width: 800px;
      margin: 0 auto;
      font-size: 48px;
      color: #e57373;
    }
  }
  .blog__articlesWrap {
    max-width: 800px;
    margin: 0 auto;
    padding: 15px;
    .blog__article {
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .blog__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding-bottom: 20px;
        margin-bottom: 20px;
        h3 {
          font-size: 18px;
        }
        .blog__date {
          font-size: 14px;
        }
      }
      .blog__body {
        h2 {
          font-size: 18px;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          margin-bottom: 10px;
          line-height: 1.5;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
}
