$Roboto: "Roboto", sans-serif;
.outerHeader {
  font-family: $Roboto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 0;
  transition: 0.5s;
  z-index: 900;
  &.sticky {
    background: rgba(255, 255, 255, 0.8);
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .outerHeader__logo {
    img {
      max-width: 100%;
    }
  }
  .outerHeader__burger {
    display: none;
    @media screen and (max-width: 1030px) {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1500;
      cursor: pointer;
      svg {
        fill: #00acc1;
      }
    }
  }
  .outerHeader__nav {
    display: flex;
    align-items: center;
    transition: 0.3s;
    @media screen and (max-width: 1030px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgb(231, 231, 231);
      flex-direction: column;
      justify-content: center;
      z-index: 1100;
      transform: translateX(-100%);
      &.opened {
        transform: translateX(0);
      }
    }
    .outerHeader__links {
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 1030px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .outerHeader__link {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        color: #00acc1;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 2px;
          background: #00acc1;
          transition: 0.3s;
          opacity: 0;
        }
        &:hover {
          &::after {
            opacity: 1;
          }
        }
        &:not(:last-child) {
          margin-right: 25px;
          @media screen and (max-width: 1030px) {
            margin-right: 0;
            margin-bottom: 20px;
            min-width: 100px;
          }
        }
        &.active {
          &::after {
            opacity: 1;
          }
        }
        svg {
          margin-right: 15px;
        }
      }
    }

    .outerHeader__socials {
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 10px;
      @media screen and (max-width: 1030px) {
        height: auto;
      }
      .outerHeader__social {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          fill: #00acc1;
          @media screen and (max-width: 992px) {
            fill: #000;
          }
        }
        &:nth-child(2) {
          margin: 0 10px;
        }
      }
    }
    .outerHeader__login {
      padding: 0;
      color: #00acc1;
      font-size: 16px;
      font-weight: 700;
      background: none;
      text-transform: none;
      @media screen and (max-width: 1030px) {
        margin-bottom: 20px;
      }
      @media screen and (max-width: 992px) {
        color: #000;
      }
    }
  }
}
