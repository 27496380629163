$Roboto: "Roboto", sans-serif;
.homePage {
  .mainScreen {
    background: url("../../../assets/img_easy/mainScreen_bg.jpg") no-repeat center top/cover;
    // min-height: 100vh;
    padding: 330px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 1850px) {
      padding: 250px 0;
    }
    @media screen and (max-width: 992px) {
      align-items: center;
      padding: 0;
      min-height: 50vh;
    }
    .mainScreen__wrap {
      margin-left: 34vw;
      @media screen and (max-width: 992px) {
        margin-left: 0;
        padding: 0 15px;
      }
      img {
        max-width: 100%;
      }
      h1 {
        font-family: $Roboto;
        font-size: 25px;
        font-weight: 300;
        color: #00acc1;
        text-transform: uppercase;
        @media screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
  .pluses {
    padding: 50px 0;
    .pluses__items {
      padding-bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      max-width: 900px;
      margin: 0 auto;
      .pluses__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33%;
        @media screen and (max-width: 530px) {
          width: 50%;
        }
        .pluses__pic {
          img {
            max-width: 100%;
          }
        }
        .pluses__text {
          margin-top: 15px;
          max-width: 150px;
          text-align: center;
        }
      }
    }
    .pluses__find {
      background: url("../../../assets/img_easy/rate.png") no-repeat 45% 50%/40%;
      padding: 130px 0;
      h2 {
        font-size: 36px;
        font-weight: 700;
        color: #00838f;
        text-align: center;
        margin-bottom: 70px;
      }
    }
  }
  .nec {
    background: url("../../../assets/img_easy/nec.png") no-repeat center top/cover;
    padding: 60px 0;
    h2 {
      text-transform: uppercase;
      font-size: 36px;
      color: #00838f;
      max-width: 550px;
      margin: 0 auto 30px;
    }
    p {
      // font-size: 18px;
      color: #000;
      line-height: normal;
      max-width: 550px;
      margin: 0 auto;
    }
  }
  .why {
    background: url("../../../assets/img_easy/why.png") no-repeat right top/cover;
    padding: 50px 0;
    h2 {
      font-size: 36px;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    p {
      max-width: 1000px;
      line-height: normal;
    }
  }
  .us {
    background: url("../../../assets/img_easy/us.png") no-repeat center top/cover;
    padding: 60px 0;
    h2 {
      text-align: center;
      text-transform: uppercase;
      font-size: 36px;
      color: #f44336;
      margin: 0 auto 30px;
    }
    p {
      // font-size: 18px;
      line-height: normal;
      color: #000;
      max-width: 450px;
      margin: 0 auto;
    }
  }
  .pluses2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1500px;
    margin: 0 auto;
    padding: 50px 0;
    .pluses2__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33%;
      @media screen and (max-width: 800px) {
        width: 50%;
      }
      @media screen and (max-width: 480px) {
        width: 100%;
      }
      .pluses2__pic {
        min-height: 200px;
        display: flex;
        align-items: center;
        img {
          max-width: 100%;
        }
      }
      p {
        font-size: 24px;
        text-align: center;
        color: #00838f;
      }
    }
  }
  .about {
    background: url("../../../assets/img_easy/about.png") no-repeat center/contain;
    padding: 80px 0;
    margin-bottom: 50px;
    h2 {
      max-width: 800px;
      font-size: 30px;
      color: #00838f;
      margin: 0 auto 30px;
    }
    p {
      font-size: 18px;
      color: #000;
      max-width: 800px;
      margin: 0 auto;
    }
  }
}
