.outerFooter {
  background: #00bcd4;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  button,
  a {
    text-transform: none;
    font-size: 14px;
    color: #fff;
  }
}
