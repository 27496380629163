.findDoctor {
  .findDoctor__header {
    margin-top: 40px;
    padding: 80px 15px;
    background: linear-gradient(to left, #ffffff00, #ffffffc9 40%, #fff 100%),
      url("../../../assets/img_easy/blog_bg.png") no-repeat center/cover;
    @media screen and (max-width: 576px) {
      padding: 20px 15px;
      margin-top: 50px;
    }
    h1 {
      font-family: "Roboto Slab", serif;
      max-width: 800px;
      margin: 0 auto;
      font-size: 48px;
      color: #e57373;
    }
    p {
      font-size: 16px;
      max-width: 800px;
      margin: 30px auto 0;
    }
  }
  .findDoctor__items {
    max-width: 1100px;
    margin: 0 auto;
    padding: 15px;
    .findDoctor__item {
      display: flex;
      // justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .findDoctor__pic {
        box-shadow: 0 -3px 3px 2px rgba(0, 0, 0, 0.2);
        margin-right: 15px;
        max-width: 200px;
        max-height: 200px;
        object-fit: cover;
      }
      .findDoctor__text {
        box-shadow: 0 -3px 3px 2px rgba(0, 0, 0, 0.2);
        padding: 40px;
        width: 100%;
        h6 {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
        }
        p {
          font-size: 14px;
        }
      }
    }
  }
}
