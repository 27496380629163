.loginLayout {
  .lang {
    display: flex;
    align-items: center;
    margin: 0 -5px 0 25px;
    @media screen and (max-width: 1030px) {
      margin: 20px 0;
    }
    .icon-lang {
      display: none;
    }
    .select {
      font-size: 16px;
      font-weight: 700;
      color: #00acc1;
      // margin: 0 35px 0 40px;
      svg {
        display: none;
      }
    }
    .MuiInput-underline:before {
      left: 0;
      right: 0;
      bottom: 0;
      content: "\00a0";
      position: absolute;
      transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      border-bottom: 0px solid rgba(0, 0, 0, 0.42);
      pointer-events: none;
    }
    .MuiInput-underline:after {
      border-bottom: 0px solid rgba(0, 0, 0, 0.42);
    }
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 0px solid rgba(0, 0, 0, 0.42);
    }
  }
}
.mainLayout {
  .lang {
    // margin: 0 -5px 0 25px;
  }
}
