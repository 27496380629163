.footerDialog {
  .footerDialog__wrap {
    padding: 20px;
    position: relative;
    .footerDialog__close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 500;
    }
    .btnWrap {
      display: flex;
      justify-content: center;
      .btn {
        background: #e57373 !important;
        border-radius: 26px !important;
        padding: 10px 20px !important;
        color: #fff !important;
        font-size: 18px !important;
        transition: 0.5s !important;
        margin-top: 20px;
        &:hover {
          background: #00838f !important;
        }
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      background: rgba(0, 0, 0, 0.5);
      .MuiCircularProgress-colorPrimary {
        color: #b0ced1;
      }
      span {
        font-size: 24px;
        max-width: 250px;
        font-weight: 700;
        color: #fff;
        text-align: center;
      }
    }
  }
}
